import logo from './logo.svg';
import './App.css';
import { Navbar } from './component/navbar/navbar.component';
import { Link } from './component/link/link.component';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { HadiSiteRouter } from './router/router';
import React from 'react';

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <HadiSiteRouter></HadiSiteRouter>
      </BrowserRouter>
    </React.StrictMode>
  )
}

export default App;
